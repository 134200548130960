import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ButtonOrangeArrow from 'components/elements/ButtonOrangeArrow'

const StyledGallery = styled.section`
  position: relative;
`

const GalleryImage = styled(Image)`
  position: relative;
`

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Gallery: React.FC<GalleryProps> = ({ fields }) => (
  <StyledGallery className="my-lg-5">
    <div className="container py-4 py-lg-5">
      <div className="row mt-sm-0 mt-4">
        <div className="col-6 pt-4 pt-sm-5">
          <div className="row justify-content-end">
            <div className="col-12 col-md-11 col-lg-10">
              <GalleryImage image={fields?.images![0]?.image} />
            </div>
            {fields?.images![1] && (
              <div className="col-sm-10 col-md-9 col-lg-7 mt-4">
                <GalleryImage image={fields.images[1].image} />
              </div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            {fields?.images![2] && (
              <div className="col-sm-10 col-md-9 col-lg-7">
                <GalleryImage image={fields.images[2].image} />
              </div>
            )}
            {fields?.images![3] && (
              <div className="col-12 col-md-11 col-lg-10 mt-4">
                <GalleryImage image={fields.images[3].image} />
                <ButtonOrangeArrow
                  className="mt-4 d-sm-block d-none"
                  to={fields.link?.url || ''}
                  target={fields.link?.target}
                >
                  {fields.link?.title}
                </ButtonOrangeArrow>
              </div>
            )}
          </div>
        </div>
      </div>

      <ButtonOrangeArrow
        className="mt-4 d-sm-none"
        to={fields.link?.url || ''}
        target={fields.link?.target}
      >
        {fields.link?.title}
      </ButtonOrangeArrow>
    </div>
  </StyledGallery>
)

export default Gallery
